import { useEffect, useState } from "react";
import Modal from "./components/Modal/Modal";

import "./styles.css";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

function App() {
  const [open, setOpen] = useState(false);
  const [autoShown, setAutoshown] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  useEffect(() => {
    if(autoShown) {
      return ;
    }

    setTimeout(() => {
      if(!open) {
        setOpen(true);
        setAutoshown(true);
      }
    }, 30*1000);
  }, [open, autoShown]);

  const handleOpenTooltip = () => {
    setTooltipOpened(true);
  };

  const handleCloseTooltip = () => {
    setTooltipOpened(false);
  };

  const handleOpen = () => {
    setOpen(true);
    handleCloseTooltip();
  };

  const handleClose = (success?: boolean) => {
    setOpen(false);

    if (success) {
      alert("Great success!");
    }
  };

  return (
    <>
      <Modal open={open} handleClose={handleClose} />
      {!open && (
        <Tooltip
          classes={{ arrow: "ath-arrow" }}
          open={tooltipOpened}
          placement="left"
          arrow
          title={
            <>
              <Button onClick={handleCloseTooltip} />

              <div className="tooltip-text-wrapper">
                <span>Frågor?</span>
                <span>Klicka här för att bli uppringd inom 55 sekunder!</span>
              </div>
            </>
          }
        >
          <Button
            onMouseOver={handleOpenTooltip}
            className="button round fixed"
            onClick={handleOpen}
          />
        </Tooltip>
      )}
    </>
  );
}

export default App;
