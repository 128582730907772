import { MenuItem, Select } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

import ReactCountryFlag from "react-country-flag";

type Option = { countryFlag: string; countryName: string; countryCode: string };

type CustomSelectMenuProps = {
  options: Option[];
} & SelectInputProps;

const SelectMenu = ({ options, onChange, value }: CustomSelectMenuProps) => {
  return (
    <Select
      value={JSON.stringify(value)}
      className="select"
      onChange={onChange}
    >
      {options.map((option) => (
        <MenuItem key={option.countryFlag} value={JSON.stringify(option)}>
          <ReactCountryFlag countryCode={option.countryFlag} />
          <div className="country-name">{option.countryName}</div>
          <div className="country-code">{option.countryCode}</div>
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectMenu;
