const callNumber = async (phoneNumber: string) => {
    return fetch('https://call-create-r4xjvao5nq-uc.a.run.app', {
        method: 'POST',
        body: JSON.stringify({
            "data": {"phone_number": phoneNumber, location: window.location.href}
        }),
        headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
    })
}

export default callNumber;