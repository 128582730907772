import { ChangeEvent, useCallback, useEffect, useState } from "react";

import { Button } from "@material-ui/core";

import ModalBody from "./ModalBody";
import Input from "../Input/Input";
import callNumber from "../services/call";
import SelectMenu from "../SelectMenu/SelectMenu";
import { COUNTRY_LIST } from "../../countryList";
import { isClassLike } from "typescript";

type CustomModalProps = {
  open: boolean;
  handleClose: (success?: boolean) => void;
};

const Modal = ({ open, handleClose }: CustomModalProps) => {
  const [phoneNumber, setNumber] = useState("");
  const [isCalling, setIsCalling] = useState(false);
  const [callStatus, setCallStatus] = useState({status: 'none'});
  const [secs, setSecs] = useState(55000);
  const [selectValue, setSelectValue] = useState<{
    countryFlag: string;
    countryName: string;
    countryCode: string;
  }>(COUNTRY_LIST[167]);

  const handleChangeNumber = (e: ChangeEvent<any>) => {
    const value = e.target.value;

    setNumber(value);
  };

  const handleChangeSelect = (
    e: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const value = e.target.value as string;

    setSelectValue(JSON.parse(value));
  };

  const onCall = useCallback(async () => {
    setIsCalling(true);

    const result = await callNumber(phoneNumber);
    const data = await result.json();
    
    setIsCalling(false);
    setCallStatus({
      status: 'started'
    });
  }, [phoneNumber]);

  useEffect(() => {
    if (secs === 0 || !isCalling) {
      return;
    }
    //Implementing the setInterval method
    const interval = setTimeout(() => {
      setSecs((prevTime) => prevTime - 10);
    }, 10);

    //Clearing the interval
    return () => clearTimeout(interval);
  }, [secs, isCalling]);

  useEffect(() => {
    setNumber(selectValue.countryCode);
  }, [selectValue]);

  return open ? (
    <div className="sales-widget">
      <dialog className="modal" open={open}>
        <ModalBody>
          <div className="modal-wrapper">
            <div className="logo-wrapper">
              <img
                src="https://app.leadconnect.cc/images/saved/5e253f9fa8f7e576bb639e355d10b744.png"
                alt="Brightsales Logo"
                style={{ width: "220px" }}
              />
            </div>
            <div className="text-wrapper">
              {isCalling ? (
                <>
                  <h3>Tak för att du beställde ett samtal</h3>
                  <span>Vi kopplar nu ditt samtal.</span>
                </>
              ) : (
                <>
                  <h3>Bli uppringd inom 55 sekunder!</h3>
                  <span>Lämna ditt nummer, så hjälper vi dig!</span>
                </>
              )}
            </div>
          </div>

          <div className="form-wrapper">
          {!isCalling && (callStatus.status === 'missed' || callStatus.status === 'failed') && 
            <div className="counter-wrapper">
              <h4>{`Tyvärr kan vi inte ta ditt samtal just nu, vi återkommer så snart vi kan.`}</h4>
            </div>
            }
            {!isCalling && callStatus.status === 'started' && 
            <div className="counter-wrapper">
              <h4>{`Ditt samtal har startat.`}</h4>
            </div>
            }
            {!isCalling && callStatus.status === 'completed' && 
            <div className="counter-wrapper">
              <h4>{`Thank you for your interest!`}</h4>
            </div>
            }
            {isCalling && (
              <div className="counter-wrapper">
                <span>{`Vi ringer nu på ${phoneNumber}:`}</span>

                <div className="timer">
                  <div className="timer-min">
                    <span>
                      {("0" + Math.floor((secs / 60000) % 60)).slice(-2)}:
                    </span>

                    <span>min</span>
                  </div>

                  <div className="timer-sec">
                    <span>
                      {("0" + Math.floor((secs / 1000) % 60)).slice(-2)}:
                    </span>

                    <span>sec</span>
                  </div>

                  <div className="timer-ms">
                    <span>{("0" + ((secs / 10) % 100)).slice(-2)}</span>

                    <span>ms</span>
                  </div>
                </div>
              </div>
            )}
            {!isCalling && callStatus.status === 'none' && (
              <>
                <span>
                  📞 <strong>Skriv in ditt nummer </strong> (samtalet är gratis)
                </span>

                <div className="form">
                  <div className="input-wrapper">
                    <Input
                      value={phoneNumber}
                      type="text"
                      name="phone-number"
                      id="phone-number"
                      style={{ height: 40, display: "flex", flex: 1 }}
                      onChange={handleChangeNumber}
                    />

                    <SelectMenu
                      value={selectValue}
                      options={COUNTRY_LIST}
                      autoWidth={false}
                      multiple={false}
                      native={false}
                      onChange={handleChangeSelect}
                    />
                  </div>

                  <Button onClick={onCall}>Ring mig!</Button>
                </div>
              </>
            )}
          </div>
        </ModalBody>

        <Button className="close-button" onClick={() => {
          setIsCalling(false);
          handleClose();
        }} />
      </dialog>
    </div>
  ) : (
    <></>
  );
};

export default Modal;
