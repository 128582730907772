export const COUNTRY_LIST = [
  { countryName: "Afghanistan", countryCode: "+93", countryFlag: "AF" },
  { countryName: "Albania", countryCode: "+355", countryFlag: "AL" },
  { countryName: "Algeria", countryCode: "+213", countryFlag: "DZ" },
  { countryName: "Andorra", countryCode: "+376", countryFlag: "AD" },
  { countryName: "Angola", countryCode: "+244", countryFlag: "AO" },
  {
    countryName: "Antigua and Barbuda",
    countryCode: "+1-268",
    countryFlag: "AG",
  },
  { countryName: "Argentina", countryCode: "+54", countryFlag: "AR" },
  { countryName: "Armenia", countryCode: "+374", countryFlag: "AM" },
  { countryName: "Australia", countryCode: "+61", countryFlag: "AU" },
  { countryName: "Austria", countryCode: "+43", countryFlag: "AT" },
  { countryName: "Azerbaijan", countryCode: "+994", countryFlag: "AZ" },
  { countryName: "Bahamas", countryCode: "+1-242", countryFlag: "BS" },
  { countryName: "Bahrain", countryCode: "+973", countryFlag: "BH" },
  { countryName: "Bangladesh", countryCode: "+880", countryFlag: "BD" },
  { countryName: "Barbados", countryCode: "+1-246", countryFlag: "BB" },
  { countryName: "Belarus", countryCode: "+375", countryFlag: "BY" },
  { countryName: "Belgium", countryCode: "+32", countryFlag: "BE" },
  { countryName: "Belize", countryCode: "+501", countryFlag: "BZ" },
  { countryName: "Benin", countryCode: "+229", countryFlag: "BJ" },
  { countryName: "Bhutan", countryCode: "+975", countryFlag: "BT" },
  { countryName: "Bolivia", countryCode: "+591", countryFlag: "BO" },
  {
    countryName: "Bosnia and Herzegovina",
    countryCode: "+387",
    countryFlag: "BA",
  },
  { countryName: "Botswana", countryCode: "+267", countryFlag: "BW" },
  { countryName: "Brazil", countryCode: "+55", countryFlag: "BR" },
  { countryName: "Brunei", countryCode: "+673", countryFlag: "BN" },
  { countryName: "Bulgaria", countryCode: "+359", countryFlag: "BG" },
  { countryName: "Burkina Faso", countryCode: "+226", countryFlag: "BF" },
  { countryName: "Burundi", countryCode: "+257", countryFlag: "BI" },
  { countryName: "Cabo Verde", countryCode: "+238", countryFlag: "CV" },
  { countryName: "Cambodia", countryCode: "+855", countryFlag: "KH" },
  { countryName: "Cameroon", countryCode: "+237", countryFlag: "CM" },
  { countryName: "Canada", countryCode: "+1", countryFlag: "CA" },
  {
    countryName: "Central African Republic",
    countryCode: "+236",
    countryFlag: "CF",
  },
  { countryName: "Chad", countryCode: "+235", countryFlag: "TD" },
  { countryName: "Chile", countryCode: "+56", countryFlag: "CL" },
  { countryName: "China", countryCode: "+86", countryFlag: "CN" },
  { countryName: "Colombia", countryCode: "+57", countryFlag: "CO" },
  { countryName: "Comoros", countryCode: "+269", countryFlag: "KM" },
  {
    countryName: "Congo, Democratic Republic of the",
    countryCode: "+243",
    countryFlag: "CD",
  },
  {
    countryName: "Congo, Republic of the",
    countryCode: "+242",
    countryFlag: "CG",
  },
  { countryName: "Costa Rica", countryCode: "+506", countryFlag: "CR" },
  { countryName: "Croatia", countryCode: "+385", countryFlag: "HR" },
  { countryName: "Cuba", countryCode: "+53", countryFlag: "CU" },
  { countryName: "Cyprus", countryCode: "+357", countryFlag: "CY" },
  { countryName: "Czech Republic", countryCode: "+420", countryFlag: "CZ" },
  { countryName: "Denmark", countryCode: "+45", countryFlag: "DK" },
  { countryName: "Djibouti", countryCode: "+253", countryFlag: "DJ" },
  { countryName: "Dominica", countryCode: "+1-767", countryFlag: "DM" },
  {
    countryName: "Dominican Republic",
    countryCode: "+1-809, +1-829, +1-849",
    countryFlag: "DO",
  },
  { countryName: "Ecuador", countryCode: "+593", countryFlag: "EC" },
  { countryName: "Egypt", countryCode: "+20", countryFlag: "EG" },
  { countryName: "El Salvador", countryCode: "+503", countryFlag: "SV" },
  { countryName: "Equatorial Guinea", countryCode: "+240", countryFlag: "GQ" },
  { countryName: "Eritrea", countryCode: "+291", countryFlag: "ER" },
  { countryName: "Estonia", countryCode: "+372", countryFlag: "EE" },
  { countryName: "Eswatini", countryCode: "+268", countryFlag: "SZ" },
  { countryName: "Ethiopia", countryCode: "+251", countryFlag: "ET" },
  { countryName: "Fiji", countryCode: "+679", countryFlag: "FJ" },
  { countryName: "Finland", countryCode: "+358", countryFlag: "FI" },
  { countryName: "France", countryCode: "+33", countryFlag: "FR" },
  { countryName: "Gabon", countryCode: "+241", countryFlag: "GA" },
  { countryName: "Gambia", countryCode: "+220", countryFlag: "GM" },
  { countryName: "Georgia", countryCode: "+995", countryFlag: "GE" },
  { countryName: "Germany", countryCode: "+49", countryFlag: "DE" },
  { countryName: "Ghana", countryCode: "+233", countryFlag: "GH" },
  { countryName: "Greece", countryCode: "+30", countryFlag: "GR" },
  { countryName: "Grenada", countryCode: "+1-473", countryFlag: "GD" },
  { countryName: "Guatemala", countryCode: "+502", countryFlag: "GT" },
  { countryName: "Guinea", countryCode: "+224", countryFlag: "GN" },
  { countryName: "Guinea-Bissau", countryCode: "+245", countryFlag: "GW" },
  { countryName: "Guyana", countryCode: "+592", countryFlag: "GY" },
  { countryName: "Haiti", countryCode: "+509", countryFlag: "HT" },
  { countryName: "Honduras", countryCode: "+504", countryFlag: "HN" },
  { countryName: "Hungary", countryCode: "+36", countryFlag: "HU" },
  { countryName: "Iceland", countryCode: "+354", countryFlag: "IS" },
  { countryName: "India", countryCode: "+91", countryFlag: "IN" },
  { countryName: "Indonesia", countryCode: "+62", countryFlag: "ID" },
  { countryName: "Iran", countryCode: "+98", countryFlag: "IR" },
  { countryName: "Iraq", countryCode: "+964", countryFlag: "IQ" },
  { countryName: "Ireland", countryCode: "+353", countryFlag: "IE" },
  { countryName: "Israel", countryCode: "+972", countryFlag: "IL" },
  { countryName: "Italy", countryCode: "+39", countryFlag: "IT" },
  { countryName: "Jamaica", countryCode: "+1-876", countryFlag: "JM" },
  { countryName: "Japan", countryCode: "+81", countryFlag: "JP" },
  { countryName: "Jordan", countryCode: "+962", countryFlag: "JO" },
  { countryName: "Kazakhstan", countryCode: "+7", countryFlag: "KZ" },
  { countryName: "Kenya", countryCode: "+254", countryFlag: "KE" },
  { countryName: "Kiribati", countryCode: "+686", countryFlag: "KI" },
  { countryName: "Korea, North", countryCode: "+850", countryFlag: "KP" },
  { countryName: "Korea, South", countryCode: "+82", countryFlag: "KR" },
  { countryName: "Kosovo", countryCode: "+383", countryFlag: "XK" },
  { countryName: "Kuwait", countryCode: "+965", countryFlag: "KW" },
  { countryName: "Kyrgyzstan", countryCode: "+996", countryFlag: "KG" },
  { countryName: "Laos", countryCode: "+856", countryFlag: "LA" },
  { countryName: "Latvia", countryCode: "+371", countryFlag: "LV" },
  { countryName: "Lebanon", countryCode: "+961", countryFlag: "LB" },
  { countryName: "Lesotho", countryCode: "+266", countryFlag: "LS" },
  { countryName: "Liberia", countryCode: "+231", countryFlag: "LR" },
  { countryName: "Libya", countryCode: "+218", countryFlag: "LY" },
  { countryName: "Liechtenstein", countryCode: "+423", countryFlag: "LI" },
  { countryName: "Lithuania", countryCode: "+370", countryFlag: "LT" },
  { countryName: "Luxembourg", countryCode: "+352", countryFlag: "LU" },
  { countryName: "Madagascar", countryCode: "+261", countryFlag: "MG" },
  { countryName: "Malawi", countryCode: "+265", countryFlag: "MW" },
  { countryName: "Malaysia", countryCode: "+60", countryFlag: "MY" },
  { countryName: "Maldives", countryCode: "+960", countryFlag: "MV" },
  { countryName: "Mali", countryCode: "+223", countryFlag: "ML" },
  { countryName: "Malta", countryCode: "+356", countryFlag: "MT" },
  { countryName: "Marshall Islands", countryCode: "+692", countryFlag: "MH" },
  { countryName: "Mauritania", countryCode: "+222", countryFlag: "MR" },
  { countryName: "Mauritius", countryCode: "+230", countryFlag: "MU" },
  { countryName: "Mexico", countryCode: "+52", countryFlag: "MX" },
  { countryName: "Micronesia", countryCode: "+691", countryFlag: "FM" },
  { countryName: "Moldova", countryCode: "+373", countryFlag: "MD" },
  { countryName: "Monaco", countryCode: "+377", countryFlag: "MC" },
  { countryName: "Mongolia", countryCode: "+976", countryFlag: "MN" },
  { countryName: "Montenegro", countryCode: "+382", countryFlag: "ME" },
  { countryName: "Morocco", countryCode: "+212", countryFlag: "MA" },
  { countryName: "Mozambique", countryCode: "+258", countryFlag: "MZ" },
  { countryName: "Myanmar", countryCode: "+95", countryFlag: "MM" },
  { countryName: "Namibia", countryCode: "+264", countryFlag: "NA" },
  { countryName: "Nauru", countryCode: "+674", countryFlag: "NR" },
  { countryName: "Nepal", countryCode: "+977", countryFlag: "NP" },
  { countryName: "Netherlands", countryCode: "+31", countryFlag: "NL" },
  { countryName: "New Zealand", countryCode: "+64", countryFlag: "NZ" },
  { countryName: "Nicaragua", countryCode: "+505", countryFlag: "NI" },
  { countryName: "Niger", countryCode: "+227", countryFlag: "NE" },
  { countryName: "Nigeria", countryCode: "+234", countryFlag: "NG" },
  { countryName: "North Macedonia", countryCode: "+389", countryFlag: "MK" },
  { countryName: "Norway", countryCode: "+47", countryFlag: "NO" },
  { countryName: "Oman", countryCode: "+968", countryFlag: "OM" },
  { countryName: "Pakistan", countryCode: "+92", countryFlag: "PK" },
  { countryName: "Palau", countryCode: "+680", countryFlag: "PW" },
  { countryName: "Palestine", countryCode: "+970", countryFlag: "PS" },
  { countryName: "Panama", countryCode: "+507", countryFlag: "PA" },
  { countryName: "Papua New Guinea", countryCode: "+675", countryFlag: "PG" },
  { countryName: "Paraguay", countryCode: "+595", countryFlag: "PY" },
  { countryName: "Peru", countryCode: "+51", countryFlag: "PE" },
  { countryName: "Philippines", countryCode: "+63", countryFlag: "PH" },
  { countryName: "Poland", countryCode: "+48", countryFlag: "PL" },
  { countryName: "Portugal", countryCode: "+351", countryFlag: "PT" },
  { countryName: "Qatar", countryCode: "+974", countryFlag: "QA" },
  { countryName: "Romania", countryCode: "+40", countryFlag: "RO" },
  { countryName: "Russia", countryCode: "+7", countryFlag: "RU" },
  { countryName: "Rwanda", countryCode: "+250", countryFlag: "RW" },
  {
    countryName: "Saint Kitts and Nevis",
    countryCode: "+1-869",
    countryFlag: "KN",
  },
  { countryName: "Saint Lucia", countryCode: "+1-758", countryFlag: "LC" },
  {
    countryName: "Saint Vincent and the Grenadines",
    countryCode: "+1-784",
    countryFlag: "VC",
  },
  { countryName: "Samoa", countryCode: "+685", countryFlag: "WS" },
  { countryName: "San Marino", countryCode: "+378", countryFlag: "SM" },
  {
    countryName: "Sao Tome and Principe",
    countryCode: "+239",
    countryFlag: "ST",
  },
  { countryName: "Saudi Arabia", countryCode: "+966", countryFlag: "SA" },
  { countryName: "Senegal", countryCode: "+221", countryFlag: "SN" },
  { countryName: "Serbia", countryCode: "+381", countryFlag: "RS" },
  { countryName: "Seychelles", countryCode: "+248", countryFlag: "SC" },
  { countryName: "Sierra Leone", countryCode: "+232", countryFlag: "SL" },
  { countryName: "Singapore", countryCode: "+65", countryFlag: "SG" },
  { countryName: "Slovakia", countryCode: "+421", countryFlag: "SK" },
  { countryName: "Slovenia", countryCode: "+386", countryFlag: "SI" },
  { countryName: "Solomon Islands", countryCode: "+677", countryFlag: "SB" },
  { countryName: "Somalia", countryCode: "+252", countryFlag: "SO" },
  { countryName: "South Africa", countryCode: "+27", countryFlag: "ZA" },
  { countryName: "South Sudan", countryCode: "+211", countryFlag: "SS" },
  { countryName: "Spain", countryCode: "+34", countryFlag: "ES" },
  { countryName: "Sri Lanka", countryCode: "+94", countryFlag: "LK" },
  { countryName: "Sudan", countryCode: "+249", countryFlag: "SD" },
  { countryName: "Suriname", countryCode: "+597", countryFlag: "SR" },
  { countryName: "Sweden", countryCode: "+46", countryFlag: "SE" },
  { countryName: "Switzerland", countryCode: "+41", countryFlag: "CH" },
  { countryName: "Syria", countryCode: "+963", countryFlag: "SY" },
  { countryName: "Taiwan", countryCode: "+886", countryFlag: "TW" },
  { countryName: "Tajikistan", countryCode: "+992", countryFlag: "TJ" },
  { countryName: "Tanzania", countryCode: "+255", countryFlag: "TZ" },
  { countryName: "Thailand", countryCode: "+66", countryFlag: "TH" },
  { countryName: "Timor-Leste", countryCode: "+670", countryFlag: "TL" },
  { countryName: "Togo", countryCode: "+228", countryFlag: "TG" },
  { countryName: "Tonga", countryCode: "+676", countryFlag: "TO" },
  {
    countryName: "Trinidad and Tobago",
    countryCode: "+1-868",
    countryFlag: "TT",
  },
  { countryName: "Tunisia", countryCode: "+216", countryFlag: "TN" },
  { countryName: "Turkey", countryCode: "+90", countryFlag: "TR" },
  { countryName: "Turkmenistan", countryCode: "+993", countryFlag: "TM" },
  { countryName: "Tuvalu", countryCode: "+688", countryFlag: "TV" },
  { countryName: "Uganda", countryCode: "+256", countryFlag: "UG" },
  { countryName: "Ukraine", countryCode: "+380", countryFlag: "UA" },
  {
    countryName: "United Arab Emirates",
    countryCode: "+971",
    countryFlag: "AE",
  },
  { countryName: "United Kingdom", countryCode: "+44", countryFlag: "GB" },
  { countryName: "United States", countryCode: "+1", countryFlag: "US" },
  { countryName: "Uruguay", countryCode: "+598", countryFlag: "UY" },
  { countryName: "Uzbekistan", countryCode: "+998", countryFlag: "UZ" },
  { countryName: "Vanuatu", countryCode: "+678", countryFlag: "VU" },
  { countryName: "Vatican City", countryCode: "+39-06", countryFlag: "VA" },
  { countryName: "Venezuela", countryCode: "+58", countryFlag: "VE" },
  { countryName: "Vietnam", countryCode: "+84", countryFlag: "VN" },
  { countryName: "Yemen", countryCode: "+967", countryFlag: "YE" },
  { countryName: "Zambia", countryCode: "+260", countryFlag: "ZM" },
  { countryName: "Zimbabwe", countryCode: "+263", countryFlag: "ZW" },
];
